import React, { useRef } from "react";
import Team from '../../components/motorrad/team';
import Layout from '../../components/motorrad/layout';
import SEO from '../../components/motorrad/seo';
import ImageBackground from '../../components/imagebackground';
import settings from '../../../settings';

const bannerDesktop = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/motorrad-instructors-banner.jpg`;
const bannerMobile = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/motorrad-instructors-banner-mobile.jpg`;

const InstructorsPage = () => {
  const contentRef = useRef(null)

  const scrollToContent= ()=>{
    contentRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <Layout>
    <SEO title="BMW U.S. Rider Academy | Motorrad Instructors" description="Internationally-acclaimed instructors are ready to redefine your comfort zone for a full-throttle BMW Motorrad experience that's as safe as it is exhilarating!" />
    <div className="instructors-page">
      <ImageBackground imageDesktop={bannerDesktop} imageMobile={bannerMobile} imageAlt="Instructors Motorcycle box" scrollTo={scrollToContent} />
      <div className="container" ref={contentRef}><Team /></div>
    </div>
  </Layout>
  );
}

export default InstructorsPage;